import { Apple } from '../LoginOptions/Apple'
import { Facebook } from '../LoginOptions/Facebook'
import { OtpLoginOption } from '../LoginOptions/Otp'
import {
    ModalPrimaryText,
    LoginContentContainer,
    LoginOptionsContainer,
    LoginButton,
    ModalTnCText,
    Divider,
} from './styles'
import { EventTracking } from '@/helpers/eventTracking'
import { useCheckIsInAppBrowser } from '@/hooks/useOpenSystemBrowser'
import { setShowLoginPrompt } from '@/store/actions/player'
import * as playerSelectors from '@/store/selectors/player'
import { Modal } from 'antd'
import { signIn, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

function LoginModal() {
    const dispatch = useDispatch()
    const showLoginPrompt = useSelector(playerSelectors._showLoginPrompt)
    const { data: session } = useSession()
    const router = useRouter()

    const [loading, setLoading] = useState(false)

    const isTransactionPage =
        router.pathname.includes('/content') ||
        router.pathname.includes('/subscription')

    const isInAppBrowser = useCheckIsInAppBrowser()

    const isCarLogin = router.pathname.startsWith('/car-login')
    const isVoucher = router.pathname.startsWith('/voucher')

    return (
        <Modal
            open={showLoginPrompt}
            onOk={() => dispatch(setShowLoginPrompt(false))}
            onCancel={() => {
                if (isCarLogin || isVoucher) return
                else dispatch(setShowLoginPrompt(false))
            }}
            footer={null}
            centered
            style={{ maxWidth: '360px' }}
            className="login-modal"
            closeIcon={
                isCarLogin || isVoucher ? null : (
                    <img src="/img/close_icon_modal.svg" alt="close icon" />
                )
            }
        >
            {/* <NoiceLogoContainer>
                <NoiceLogo loading="lazy" src={'/img/new-noice-logo.svg'} />
            </NoiceLogoContainer> */}

            <LoginContentContainer isTransactionPage={isTransactionPage}>
                <ModalPrimaryText>Masuk / Daftar</ModalPrimaryText>

                <OtpLoginOption />

                {(!isInAppBrowser || !isTransactionPage) && (
                    <Divider>
                        <div className="divide"></div>
                        <span>atau lanjut dengan</span>
                        <div className="divide"></div>
                    </Divider>
                )}

                <LoginOptionsContainer>
                    {!isInAppBrowser && (
                        <LoginButton
                            onClick={() => {
                                setLoading(true)
                                signIn('google', {
                                    redirect: false,
                                })
                                EventTracking('login', { loginType: 'google' })
                            }}
                        >
                            <img src="/img/google_login.svg" alt="" />
                        </LoginButton>
                    )}

                    {!isInAppBrowser && <Facebook token={session?.token} />}

                    {!isTransactionPage && <Apple />}
                </LoginOptionsContainer>

                <ModalTnCText>
                    Dengan Masuk atau Daftar, kamu setuju dengan{' '}
                    <Link href={'/tnc'} prefetch={false}>
                        <a
                            style={{ color: 'yellow' }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Syarat dan Ketentuan
                        </a>
                    </Link>{' '}
                    yang berlaku.
                </ModalTnCText>
            </LoginContentContainer>

            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#0000009e',
                        borderRadius: '16px',
                    }}
                >
                    <Spinner style={{ color: '#fad810' }} size="md" />
                </div>
            )}
        </Modal>
    )
}

export default LoginModal
